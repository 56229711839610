import Phaser from 'phaser'
import TextureKeys from '~/consts/TextureKeys'
import RetinaText from '~/classes/RetinaText';
import RetinaSprite from '~/classes/RetinaSprite';
import {gameHeight, gameWidth} from '~/utils/Resolution';
import {helpButton, primaryButton} from '~/ui/Button';
import SceneKeys from '~/consts/SceneKeys';
import RetinaDOMElement from '~/classes/RetinaDOMElement';
import Slot from '~/classes/Slot';
import GameEvents from '~/consts/GameEvents';
import IEmoji from '~/classes/IEmoji';

export default class Game extends Phaser.Scene {
    private isSpinning = false
    private slots: Slot[] = []
    private playButton?: RetinaDOMElement
    private continueButton?: RetinaDOMElement
    private griff?: RetinaSprite
    private helpButton?: RetinaDOMElement
    private helpText?: Phaser.GameObjects.Text
    private emojis?: IEmoji[]
    private riddle?: IEmoji

    constructor() {
        super(SceneKeys.Game);
    }

    preload() {
        this.load.json('emojis', 'assets/json/emojis-v4.json');
    }


    create() {
        this.emojis = this.cache.json.get('emojis');
        this.cache.json.remove('emojis');
        this.slots = []

        const slotmachine = new RetinaSprite(this, gameWidth / 2 - 15, gameHeight / 2 - 80, TextureKeys.Theme, TextureKeys.Slotmachine)
            .setOrigin(0.5, 0.5)

        this.griff = new RetinaSprite(this, slotmachine.retinaX + 188, slotmachine.retinaY - 80, TextureKeys.Theme, TextureKeys.Griff)
            .setOrigin(0.5, 0.5).setInteractive().on('pointerup', this.handlePlay, this)

        const logo = new RetinaSprite(this, gameWidth / 2, 80, TextureKeys.Theme, TextureKeys.Logo).setOrigin(0.5, 0);

        const today = (new Date()).toISOString().slice(0, 10).replace(/-/g, '');
        // Debug
        const today='20210711'
        this.riddle = {
            date: today,
            target1: 1,
            target2: 1,
            target3: 1,
            help: ''
        }
        if (this.emojis) {
            this.emojis.forEach((element: IEmoji) => {
                if (element.date === today) {
                    this.riddle = element;
                }
            });
        }


        let slot = new Slot(this, slotmachine.retinaX - 100, slotmachine.retinaY + 125).setOrigin(0.5, 1)
        slot.setID(0)
            .setTarget(this.riddle.target1);
        this.slots.push(slot);

        slot = new Slot(this, slotmachine.retinaX + 0, slotmachine.retinaY + 125).setOrigin(0.5, 1)
        slot.setID(1)
            .setTarget(this.riddle.target2);
        this.slots.push(slot);

        slot = new Slot(this, slotmachine.retinaX + 100, slotmachine.retinaY + 125).setOrigin(0.5, 1)
        slot.setID(2)
            .setTarget(this.riddle.target3);
        this.slots.push(slot);


        this.playButton = new RetinaDOMElement(this, slotmachine.retinaX, gameHeight * 0.8, primaryButton('Play') as HTMLElement)
            .addListener('click').on('click', this.handlePlay, this);

        this.continueButton = new RetinaDOMElement(this, slotmachine.retinaX, gameHeight * 0.8, primaryButton('Ich weiss es') as HTMLElement)
            .addListener('click').on('click', () => {
                this.scene.start(SceneKeys.Contest)
            })

        this.helpButton = new RetinaDOMElement(this, slotmachine.retinaX, 480, helpButton('Help') as HTMLElement)
            .addListener('click').on('click', () => {
                this.tweens.add({
                    targets: this.helpText,
                    duration: 500,
                    alpha: 1
                })
                this.helpButton?.setVisible(false);
            })

        this.helpText = new RetinaText(this, slotmachine.retinaX, 565, this.riddle.help, {
            fontSize: 20,
            fontFamily: 'Crewniverse',
            align: 'center',
            lineSpacing: 30,
        }).setOrigin(0.5, 0).setAlpha(0);

        this.playButton?.setVisible(true);
        this.helpButton?.setVisible(false);
        this.continueButton?.setVisible(false);

        this.slots[2].on(GameEvents.SlotmachineFinished, () => {
            this.time.delayedCall(1400, () => {
                if (this.helpButton) {
                    this.helpButton.setAlpha(0).setVisible(true);
                    this.tweens.add({
                        targets: this.helpButton,
                        duration: 500,
                        alpha: 1
                    })
                }
            }, [], this)

            if (this.riddle && this.riddle.help != '') {
                this.continueButton?.setVisible(true);
            }

        })

        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.handleShutdown()
        })
    }

    private handlePlay() {
        if (this.griff) {
            this.griff.play('pull');
            this.griff.off('pointerup');
        }

        this.time.delayedCall(500, () => {
            this.slots[0].spinStart()
        }, [], this)
        this.time.delayedCall(1000, () => {
            this.slots[1].spinStart()
        }, [], this)
        this.time.delayedCall(2000, () => {
            this.slots[2].spinStart()
        }, [], this)
        this.playButton?.setVisible(false);

    }

    private handleShutdown() {

    }
}
